import { string as YupString } from "yup"

import { useTranslation } from "src/hooks/translation"

export const useServiceInquiryYupSchema = () => {
  const { messages } = useTranslation()
  const validationMessages =
    messages.pages.services.flow.services.inquiry.form.validations

  const createServiceInquiryYupSchema = () => ({
    subject: YupString().required(validationMessages.subject.required),
    message: YupString()
      .max(500, validationMessages.message.maxLength)
      .required(validationMessages.message.required),
  })

  return {
    createServiceInquiryYupSchema,
  }
}
