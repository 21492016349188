import { useMemo } from "react"

import { SelectOption } from "src/components/common/select"
import { useTranslation } from "src/hooks/translation"

export function useInquirySubjects() {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.services.inquiry

  return {
    subjects: useMemo<SelectOption[]>(
      () => [
        {
          label: translations.form.subject.one,
          value: "SERVICE_INQUIRY",
        },
        {
          label: translations.form.subject.two,
          value: "ADD_DETAILS_TO_BOOKING",
        },
      ],
      [translations],
    ),
    defaultSubject: "ADD_DETAILS_TO_BOOKING",
  }
}
