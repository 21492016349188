import { forwardRef, InputHTMLAttributes, useState } from "react"

import { Icon } from "../icon"
import { InputError } from "../input/InputError"
import { create } from "src/helpers/bem"

import styles from "./TextArea.module.scss"

const bem = create(styles, "TextArea")

export type TextAreaProps = {
  error?: string
  fieldClassName?: string
} & InputHTMLAttributes<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      children,
      className,
      value,
      id,
      error,
      placeholder,
      fieldClassName,
      maxLength,
      disabled = false,
      onFocus,
      onBlur,
      ...attributes
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)

    const modifiers = {
      "is-disabled": disabled,
      "is-elevated": focused || !!value,
      "is-focused-without-error": focused && !error,
      "has-error": !!error,
    }
    return (
      <div className={bem(undefined, undefined, className)}>
        {children && (
          <label className={bem("label", modifiers)} htmlFor={id}>
            {children}
          </label>
        )}
        <div className={bem("field-wrapper", modifiers, fieldClassName)}>
          <textarea
            ref={ref}
            className={bem("field", { "is-disabled": disabled })}
            id={id}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={(e) => {
              setFocused(true)
              onFocus?.(e)
            }}
            onBlur={(e) => {
              setFocused(false)
              onBlur?.(e)
            }}
            {...attributes}
          />
          {error && (
            <div className={bem("error")}>
              <Icon className={bem("icon")} name="warningCircledFill" />
            </div>
          )}
        </div>
        {error && <InputError id={`${id}-error`}>{error}</InputError>}
      </div>
    )
  },
)

TextArea.displayName = "TextArea"
