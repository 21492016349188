import { useRouter } from "next/router"
import { FC } from "react"

import { Modal, ModalContent } from "src/components/common/modal"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import { Inquiry } from "src/states/serviceFlow/inquiryActions"

import { ServiceInquiryForm } from "./ServiceInquiryForm"

import styles from "./ServiceInquiryModal.module.scss"

const bem = create(styles, "ServiceInquiryModal")

type ServiceInquiryModalProps = {
  open: boolean
  inquiry?: Inquiry
  canEdit?: boolean
  setDefaultSubject?: boolean
  onOpenChange: (open: boolean) => void
}

export const ServiceInquiryModal: FC<ServiceInquiryModalProps> = ({
  open,
  inquiry,
  onOpenChange,
  canEdit,
  setDefaultSubject,
}) => {
  const router = useRouter()
  const openModal = open ?? !!router?.query?.openInquiry

  const { messages, formatMessageRaw } = useTranslation()
  const translations = messages.pages.services.flow.services.inquiry.modal

  const formattedText = formatMessageRaw(translations.text1, {
    bold: (text: string) => (
      <span className={bem("bold")} key={text}>
        {text}
      </span>
    ),
  })

  return (
    <Modal open={openModal} onOpenChange={onOpenChange}>
      <ModalContent title={translations.title}>
        <div className={bem()}>
          <div className={bem("header")}>
            <p className={bem("text")}>{formattedText}</p>
            <p className={bem("text")}>{translations.text2}</p>
          </div>
          <ServiceInquiryForm
            canEdit={canEdit}
            inquiry={inquiry}
            onOpenChange={onOpenChange}
            setDefaultSubject={setDefaultSubject}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
