import { mergeServiceFlowData } from "./serviceFlow"

export type Inquiry = {
  message: string
  subject: string
  inquiryType: "SERVICE_INQUIRY" | "ADD_DETAILS_TO_BOOKING"
}

const initalState: Inquiry | undefined = undefined

const inquiryActions = {
  clearInquiry: () => {
    mergeServiceFlowData({ inquiry: initalState })
  },
  addInquiry: (inquiry: Inquiry) => {
    mergeServiceFlowData({ inquiry })
  },
}

export default inquiryActions
