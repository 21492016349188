import { FormikConfig } from "formik"
import Router from "next/router"
import { FC } from "react"
import { object as YupObject } from "yup"

import { useInquirySubjects } from "../hooks/useInquirySubjects"
import { useServiceInquiryYupSchema } from "../hooks/useServiceInquiryYupSchema"
import { Button } from "src/components/common/button"
import { Form } from "src/components/common/form"
import { Select } from "src/components/common/select"
import { TextArea } from "src/components/common/textArea/TextArea"
import { ROUTES } from "src/config"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import { useFlow } from "src/hooks/useFlow"
import {
  closeNotificationModal,
  openNotificationModal,
} from "src/states/notificationModal"
import { useServiceFlowData } from "src/states/serviceFlow"

import inquiryActions, { Inquiry } from "src/states/serviceFlow/inquiryActions"

import styles from "./ServiceInquiryForm.module.scss"

const bem = create(styles, "ServiceInquiryForm")

type ServiceInquiryFormProps = {
  inquiry?: Inquiry
  canEdit?: boolean
  setDefaultSubject?: boolean
  onOpenChange: (open: boolean) => void
}

export const ServiceInquiryForm: FC<ServiceInquiryFormProps> = ({
  inquiry,
  canEdit,
  setDefaultSubject,
  onOpenChange,
}) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.services.inquiry

  const { serviceCategories } = useServiceFlowData()
  const { flowStep } = useFlow()

  const subjectDescription: { [key: string]: string } = {
    SERVICE_INQUIRY: translations.form.subject.one,
    ADD_DETAILS_TO_BOOKING: translations.form.subject.two,
  }

  const { subjects, defaultSubject } = useInquirySubjects()
  const { createServiceInquiryYupSchema } = useServiceInquiryYupSchema()

  const ServiceInquiryFormSchema = YupObject().shape(
    createServiceInquiryYupSchema(),
  )

  const initialFormData = {
    subject: inquiry?.inquiryType || (setDefaultSubject ? defaultSubject : ""),
    message: inquiry?.message || "",
  }

  const onSubmit: FormikConfig<any>["onSubmit"] = async (values) => {
    const inquiry = {
      inquiryType: values.subject,
      subject: subjectDescription[values.subject],
      message: values.message,
    }
    inquiryActions.addInquiry(inquiry)
    onOpenChange(false)
  }

  //If inquiry is deleted and service list is empty we redirect the user to our home page
  const deleteInquiryAndRedirectToHome = () => {
    inquiryActions.clearInquiry()
    Router.push(ROUTES.index)
  }

  const closeInquiryFormModal = () => {
    closeNotificationModal()
    onOpenChange(false)
  }

  const onClickDeleteInquiry = () => {
    if (!serviceCategories?.length && flowStep) {
      //show notification only if service list is empty
      return openNotificationModal({
        size: "sm",
        title: translations.notification.title,
        content: [translations.notification.content],
        buttons: [
          {
            fullWidth: true,
            variant: "danger",
            label: translations.notification.buttons.continue,
            onClick: () => deleteInquiryAndRedirectToHome(),
            background: true,
          },
          {
            onClick: () => closeInquiryFormModal(),
            fullWidth: true,
            variant: "tertiary",
            label: translations.notification.buttons.back,
            background: false,
          },
        ],
      })
    } else {
      inquiryActions.clearInquiry()
      onOpenChange(false)
    }
  }

  const showDeleteButton = inquiry?.inquiryType && canEdit

  return (
    <>
      <Form
        name="service-inquiry-form"
        initialValues={initialFormData}
        onSubmit={onSubmit}
        validationSchema={ServiceInquiryFormSchema}
        validateOnBlur
        submitLabel={translations.form.button.confirm}
        submitButtonProps={{ size: "lg" }}
        showSubmitButton={!!canEdit}
      >
        {({ values, errors, handleChange, handleBlur, isSubmitting }) => {
          return (
            <div className={bem()}>
              <Select
                id="inquiry-subject"
                autoComplete="honorific-prefix"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                options={subjects}
                disabled={isSubmitting || !canEdit}
                placeholder={translations.form.subject.placeHolder}
                error={errors.subject}
                data-cy="inquiry-subject"
              />

              <TextArea
                id="inquiry-message"
                name="message"
                value={values.message}
                placeholder={translations.form.message.placeHolder}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting || !canEdit}
                error={errors.message}
                maxLength={500}
                data-cy="inquiry-message"
              >
                {translations.form.message.title}
              </TextArea>
            </div>
          )
        }}
      </Form>
      <div className={bem("button")}>
        {showDeleteButton ? (
          <Button
            data-cy="service-inquiry-delete"
            variant="tertiary"
            onClick={onClickDeleteInquiry}
            className={bem("delete-button")}
          >
            {translations.form.button.delete}
          </Button>
        ) : (
          <Button
            variant="tertiary"
            onClick={() => onOpenChange(false)}
            className={bem("cancel-button")}
          >
            {translations.form.button.cancel}
          </Button>
        )}
      </div>
    </>
  )
}
