import { Label as RadixLabel } from "@radix-ui/react-label"
import * as RadixSelect from "@radix-ui/react-select"
import { FormikHandlers } from "formik"
import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react"

import { Icon } from "../icon"
import { InputError } from "../input/InputError"
import { Autocomplete } from "../input/types/autocomplete"
import { Loader } from "../loader"
import { create } from "src/helpers/bem"

import styles from "./Select.module.scss"

export type SelectOption = {
  label: ReactNode
  value: string | number
}

export type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>

const bem = create(styles, "Select")

export type SelectProps = PropsWithChildren<{
  options: SelectOption[]
  name: string
  id: string
  placeholder?: string
  autoComplete?: Autocomplete
  error?: string
  disabled?: boolean
  loading?: boolean
  value?: string
  onChange?: FormikHandlers["handleChange"]
  "data-cy"?: string
  /**
   * Modifies current showing label
   * Only use non-blocking elements, e.g.: `<span>` or `string` */
  modifyLabel?: (label: ReactNode) => ReactNode
  fieldClassName?: string
  iconClassName?: string
  fullWidth?: boolean
  /**
   * Modifies option labels
   * Only use non-blocking elements, e.g.: `<span>` or `string` */
  LabelComponent?: ComponentType<PropsWithChildren>
}>

/*
  Using Radix UI Select
  https://www.radix-ui.com/docs/primitives/components/select

  This component is meant to be used inside a <Form />

  The console error "react-remove-scroll-bar" is a bug in Radix-UI and only show in
  development mode: https://github.com/radix-ui/primitives/issues/1593
*/
export const Select: FC<SelectProps> = ({
  options,
  id,
  name,
  autoComplete,
  value,
  onChange,
  placeholder,
  children,
  error,
  disabled = false,
  loading = false,
  "data-cy": dataCy,
  modifyLabel,
  fieldClassName,
  iconClassName,
  fullWidth = true,
  LabelComponent,
}) => {
  const [open, setOpen] = useState(false)
  /*
    We have to manage the open state manually because of a bug in our codebase
    This is not reproducible out of our codebase — in a fresh nextjs install, for example.
  */
  const [isFocused, setIsFocused] = useState(false)

  const modifiers = {
    "is-disabled": !!disabled,
    "has-error": !!error,
    "is-loading": !!loading,
    "is-focused": !!isFocused && !error,
  }

  const currentOption = value
    ? options.find((option) => option.value.toString() === value)
    : undefined

  const handleValueChange = (newValue: string) => {
    if (onChange) {
      onChange({ target: { id, name, value: newValue } })
    }
  }

  const getCurrentLabel = () => {
    if (!currentOption?.label) {
      return <span className={bem("placeholder")}>{placeholder}</span>
    }

    if (modifyLabel) {
      return modifyLabel(currentOption.label)
    }

    return currentOption.label
  }

  return (
    <div
      className={bem(undefined, { "full-width": fullWidth })}
      data-error={!!error}
    >
      {children && (
        <RadixLabel className={bem("label", modifiers)} htmlFor={id}>
          {children}
        </RadixLabel>
      )}

      <RadixSelect.Root
        name={name}
        value={value}
        onValueChange={handleValueChange}
        autoComplete={autoComplete}
        open={open}
        onOpenChange={setOpen}
      >
        <RadixSelect.Trigger
          id={id}
          disabled={disabled}
          className={bem("trigger", modifiers, fieldClassName)}
          data-cy={dataCy || "select-trigger"}
          data-valid={!error}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <RadixSelect.Value aria-label={value}>
            {getCurrentLabel()}
          </RadixSelect.Value>

          <div className={bem("arrow", modifiers, iconClassName)}>
            {loading ? (
              <Loader />
            ) : (
              <Icon className={bem("icon")} name="dropdownMenuDown" />
            )}
          </div>
        </RadixSelect.Trigger>

        <RadixSelect.Portal className={bem("portal")}>
          <RadixSelect.Content
            className={bem("content")}
            data-cy="select-content"
          >
            <RadixSelect.Viewport className={bem("viewport")}>
              {options.map(({ value, label }) => (
                <RadixSelect.Item
                  className={bem("item")}
                  key={value}
                  value={value.toString()}
                >
                  <RadixSelect.ItemText>
                    {LabelComponent ? (
                      <LabelComponent>{label}</LabelComponent>
                    ) : (
                      label
                    )}
                  </RadixSelect.ItemText>
                </RadixSelect.Item>
              ))}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </RadixSelect.Portal>
        {error && <InputError id="error">{error}</InputError>}
      </RadixSelect.Root>
    </div>
  )
}
